import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { getBudgetVerlauf } from 'services/budget'
import moment from 'moment';

const debug = true
const pie = {
    chart: {
        type: "pie",
        height:  (6 / 16 * 100) + '%',
        zoomType: "x",
        },
        title: {
        text: "",
        },
        credits: {
        enabled: false,
        },
        tooltip: {
        formatter: function () {
            return (
            Highcharts.dateFormat("%e %b %y", this.x) + " - " + this.y.toFixed(2)
            );
        },
        },
        colors: ["#2f7ed8", "#d033a5", "#0d233a"],
        series: [
        {
            name: 'Percentage',
            colorByPoint: true,
            data: []
        }
    ]
}

function BudgetPositionsPieChart(props) {
    const [debug, setDebug] = useState([])
    const [pieChart, setPieChart] = useState(pie)

    useEffect(() => {
        // get all 'ausgaben' Kontos
        const ausgabenKontos = _.filter(props.konten, k => { return k.kontotyp == "Ausgaben"})
        const ausgabenKontosIds = _.map(ausgabenKontos, k => { return k.Id })
        const uniqueAusgabenKontoIds = _.uniq(ausgabenKontosIds)

        // create promises to create budget for each ausgabekonto
        const promises = []
        for(let ausgabeKonto of uniqueAusgabenKontoIds) {
            promises.push(getBudgetVerlauf(ausgabeKonto, moment(props.von).format("yyyy-MM-DD"), moment(props.bis).format("yyyy-MM-DD")))
        }
        Promise.all(promises).then(results => {
            const calculatedPie = { ...pie }
            calculatedPie.series[0].data = _.map(results, r => {
                return {
                    name: r.konto,
                    y: r.differenz
                }
            })
            setDebug(calculatedPie)
        })
    }, [props.budgetPositions]);

    return (
        <React.Fragment>
            <HighchartsReact
                    highcharts={Highcharts}
                    options={pieChart}
                    containerProps={{ 
                        style: { 
                            width: "100%",
                            height:  (9 / 16 * 100) + '%' 
                        } 
                    }}
                />

            {/* Debug */}
            {debug &&
            <React.Fragment>
                <h1>Debug</h1><br />
                <table>
                    <tr>
                        <td>Von</td>
                        <td>{JSON.stringify(props.von, null, 2)}</td>
                    </tr>
                    <tr>
                        <td>Bis</td>
                        <td>{JSON.stringify(props.bis, null, 2)}</td>
                    </tr>
                    <tr>
                        <td>debug</td>
                        <td>{JSON.stringify(debug, null, 2)}</td>
                    </tr>
                </table>
            </React.Fragment>
            }
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { 
        konten: state.kontoSlice.konten,
    };
  }
  
export default connect(mapStateToProps)(BudgetPositionsPieChart)