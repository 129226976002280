const configurations = {
    "main": {
        config: "main",
        apibase: "https://finance-backend.services.opensight.ch",
        oauth: {
            token_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/token",
            auth_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/auth",
            logout_url: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/logout",
            redirect_uri: `https://finance.services.opensight.ch`,
            client_id: "finance",
        }
    },
    "dev": {
        config: "dev",
        apibase: "https://finance-backend.services.opensight.ch",
        oauth: {
            token_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/token",
            auth_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/auth",
            logout_url: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/logout",
            redirect_uri: "http://localhost:3000",
            client_id: "finance",
        }
    }
}

const config = configurations[process.env.REACT_APP_ENV] || configurations["dev"];

const exportConfig = {...config}

export default exportConfig;