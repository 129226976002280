import moment from 'moment';

export const date_today = new Date(Date.now());
export const date_startOfYear = moment(new Date(new Date(Date.now()).setMonth(0)).setDate(0)).add(1, 'days');
export const date_startOfMonth = new Date(new Date(Date.now())).setDate(0);
export const date_startOfLastMonth = moment(new Date(new Date(Date.now())).setDate(0)).add(-1, 'M');
export const date_endOfYear = new Date(new Date(Date.now()).setMonth(11)).setDate(31);
export const date_startOfLastYear = moment(date_startOfYear).add(-1, 'year');
export const date_endOfLastYear = moment(date_startOfYear).add(-1, 'days');
export const date_endOfNextYear = moment(date_endOfYear).add(+1, 'Y');
export const date_threeYearsAgo = moment(date_startOfYear).add(-3, 'Y');

// 31
export function zeitraumDiesesJahr() {
    return {
        datumVon: moment(date_startOfYear).format("yyyy-MM-DD"),
        datumBis: moment(date_endOfYear).format("yyyy-MM-DD")
    }
}
// anfang jahr bis heute
export function zeitraumAngefangenesJahr() {
    return {
        datumVon: moment(date_startOfYear).format("yyyy-MM-DD"),
        datumBis: moment(date_today).format("yyyy-MM-DD")
    }
}
export function zeitraumLetztesJahr() {
    return {
        datumVon: moment(date_startOfLastYear).format("yyyy-MM-DD"),
        datumBis: moment(date_endOfLastYear).format("yyyy-MM-DD")
    }
}
export function zeitraumNaechstesJahr() {
    return {
        datumVon: moment(date_endOfYear).format("yyyy-MM-DD"),
        datumBis: moment(date_endOfNextYear).format("yyyy-MM-DD")
    }
}
export function zeitraumDieserMonat() {
    return {
        datumVon: moment(date_startOfMonth).format("yyyy-MM-DD"),
        datumBis: moment(date_today).format("yyyy-MM-DD")
    }
}
export function zeitraumLetzterMonat() {
    return {
        datumVon: moment(date_startOfLastMonth).format("yyyy-MM-DD"),
        datumBis: moment(date_startOfMonth).format("yyyy-MM-DD")
    }
}
